<!--
 * @Author: LazyQ
 * @Date: 2020-04-13 13:21:26
 * @LastEditTime: 2020-04-21 10:50:27
 * @LastEditors: LazyQ
 -->
<style lang="less" scoped>
.model-action {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(68, 125, 245, 1);
  &:hover {
    cursor: pointer;
  }
}
</style>

<template>
  <span class="model">
    <span class="model-action" @click="showModal">重置密码</span>
    <a-modal
      title="重置密码"
      ok-text="确认"
      cancel-text="取消"
      :visible="visible"
      @ok="handleOk"
      :confirmLoading="confirmLoading"
      @cancel="handleCancel"
    >
    <p>确定要重置管理员密码嘛？</p>
      <!-- <a-form-model
        ref="pwdForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="新密码" prop="new_pwd" ref="new_pwd">
          <a-input v-model="form.new_pwd" placeholder="请输入新密码"/>
        </a-form-model-item>
      </a-form-model> -->
    </a-modal>
  </span>
</template>

<script>
import { fetchAdminResetpass } from "@/api/admin";
export default {
  props: {
    reset_admin_id: undefined
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      form: {
        new_pwd: "",
      },
      rules: {
        new_pwd: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur"
          }
        ],
      }
    };
  },
  created() {},
  methods: {
    showModal() {
      this.visible = true;
    },
    handleOk(e) {
      // const that = this;
      // this.$refs.pwdForm.validate(valid => {
      //   if (valid) {
      //     this.resetPwd();
      //   } else {
      //     return false;
      //   }
      // });
      this.resetPwd();
    },
    handleCancel(e) {
      this.form.new_pwd = undefined;
      this.visible = false;
    },
    async resetPwd() {
      this.confirmLoading = true;
      try {
        await fetchAdminResetpass({
          reset_admin_id: this.reset_admin_id,
        })
        this.$message.success("重置密码成功");
        this.form.new_pwd = undefined;
        this.confirmLoading = false;
        this.visible = false;
      } catch (error) {
        this.confirmLoading = false; 
        console.log(error);
      }
    },
   
  }
};
</script>